<template>
  <div class="weather-single">
    <div class="w-bg">
      <img src="/images/weather/bg.png" />
    </div>
    <div class="w-content">
      <div class="w-title">
        <strong>{{ model.weatherCity }}</strong>
        <span>Hava Durumu</span>
      </div>
      <div class="w-item">
        <div class="w-icon">
          <img class="effect2" :src="imagePath" />
        </div>
        <div class="w-info">
          <strong>{{ model.weatherDay }}</strong>
          <span>{{ model.weatherText }}</span>
        </div>
        <div class="w-value">
          <span>{{ model.weatherValue }}°</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store/index';
export default {
  name: 'weather-single',
  props: ['data'],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.weather;
    },
    imagePath() {
      return `/images/weather/${this.model.weatherIcon}`;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
.weather-single {
  .w-bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-position: center;
    }
  }

  .w-content {
    position: relative;
    margin: 0 auto 0;
    text-align: center;

    .w-title {
      display: inline-block;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
      padding: 12px 36px;
      margin: 130px 0 80px;

      span,
      strong {
        display: block;
        font-family: Montserrat-Bold;
        font-size: 90px;
        text-transform: uppercase;
        color: white;
        line-height: 90px;
      }

      span {
        font-size: 58px;
        line-height: 60px;
        font-family: Montserrat-Light;
      }
    }

    .w-item {
      display: inline-block;
      padding: 2px 48px 90px 120px;
      width: 1723px;
      height: 517px;
      background: url('/images/weather/weather-card-bg.png') center top repeat;
      background-size: 100% 100%;
      text-align: left;

      .w-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 90px;

        img {
          width: 436px;
          height: 420px;
          padding: 100px;
          -o-object-fit: contain;
          object-fit: contain;
        }
      }

      .w-info {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 738px;
        max-height: 204px;
        overflow: hidden;

        span,
        strong {
          display: block;
          font-family: Montserrat-SemiBold;
          font-size: 112px;
          line-height: 110px;
          color: #1f3b71;
        }

        span {
          font-size: 88px;
          line-height: 88px;
          font-family: Montserrat-Light;
          color: #989999;
        }
      }

      .w-value {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 286px;

        span {
          display: block;
          font-family: Montserrat-Bold;
          font-size: 160px;
          line-height: 160px;
          color: white;
        }
      }
    }
  }
}</style>
